<template>
    <div class="News a-fade-in" style="background-color: #F8F9FA; min-width: 1200px" v-cloak id="DivContent" >
        <div class="banner">
            <a-carousel arrows :autoplay="autoPlay" v-if="bannerList!=null&&bannerList.length>1">
                <div slot="prevArrow"
                     slot-scope="props"
                     class="custom-slick-arrow"
                     style="left: 10px; zIndex: 1">
                    <a-icon type="left" />
                </div>
                <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                    <a-icon type="right" />
                </div>
                <div v-for="(item,index) in bannerList" :key="index" style="cursor:pointer">
                    <img :src="QiNiuPath+item.newsmaxPic" @click="goDetails(item)" />
                    <h3><span class="spanNewBannerTitle">{{item.newsTitle}}</span></h3>
                </div>
            </a-carousel>

            <div v-if="bannerList!=null&&bannerList.length>0&&bannerList.length<2" class="newsbanner">
                 <img :src="QiNiuPath+bannerList[0].newsmaxPic" @click="goDetails(bannerList[0])" />
            <h3><span class="spanNewBannerTitle" >{{bannerList[0].newsTitle}}</span></h3></div>
        </div>
        
        <a-spin :spinning="spinning">
            <ul class="content">
                <NewsItem 
                    v-for="(item,index) in newsList" 
                    :key="index"
                    :newsData="item"
                    @toDetail="goDetails"
                >
                </NewsItem>
                <!-- <li  @click="goDetails(item)" v-for="(item,index) in newsList" :key="index">
                    <h2><img v-if="item.isNew=='0'" src="../assets/imgs/new_icon.png">{{item.newsTitle}}</h2>
                    <img :src="QiNiuPath+item.newsPic">
                    <p>{{item.newsIntroduce}}</p>
                    <h3>{{item.showTime}}</h3>
                </li> -->
            </ul>
        </a-spin>
        <!-- <p class="empty_txt" v-text="newsPageSize*newsCurrentPage<newsTotal?'更多内容加载中':'没有更多内容了'"></p> -->
        <div class="pages">
            <div v-show="newsTotal > 1">
                <a-pagination v-model="newsCurrentPage" :total="newsTotal * 10" @change="nextPage"/>
            </div>
        </div>
        <div v-if="newsList.length<=0" >
            <no-data promptText="暂无新闻资讯！"/>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { queryNewsPage} from '@/apis/News.js';
    import NoData from '@/pages/NoData.vue';
    import NewsItem from '@/components/common/newsitem';

    export default {
        name: 'News',
        data() {
            return {
                newsPageSize: 10,
                newsCurrentPage:1,
                newsTotal: 0,
                newsList: [],
                bannerList:[],
                autoPlay:true,
                spinning:false
            };
        },
        computed: {
            ...mapState({
                QNPath: state => state.indexStore.QNPath,
                QiNiuPath: state => state.indexStore.QiNiuPath,
                KeyWord: state => state.indexStore.KeyWord
            }),
        },
        mounted() {
            // window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
        },
        components:{
            'NoData':NoData,
            NewsItem,
        },
        created() {
            let that = this;
            that.$nextTick(() => {
                that.getBannerList();
                // that.getNewsList();
                that.getList()
            })
        },
        methods: {

            handleScroll() {
                let that = this;

                if ((document.documentElement.scrollTop + window.innerHeight + 100)>= document.documentElement.scrollHeight) {
                    if ((that.newsPageSize * that.newsCurrentPage) <that.newsTotal) {
                        that.moreData();
                    }
                   
                }
            },
            // 跳转详情
            goDetails: function (newsItem) {
                const that = this;
                if (newsItem.sourceUrl != null && newsItem.sourceUrl != undefined && newsItem.sourceUrl.trim() != '') {
                    window.open(newsItem.sourceUrl);
                }
                else {
                    that.$router.push({
                        path: '/VNewsDetails', query: { "NewsId": newsItem.newsId, "NewsSource": 1 }
                    });
                }
            },
          
            getNewsList: function () {
                let that = this;
                let params = {
                    currentPage: that.newsCurrentPage,
                    pageSize: that.newsPageSize
                };
                that.$nextTick(() => {
                    queryNewsPage(params).then(res => {
                        let msg = res.data != undefined && res.data != null ? res.data : null;
                        if (msg != null && msg.status == 0) {
                            that.newsTotal = msg.result ? msg.result.total : 0;
                            if (that.newsCurrentPage==1) {
                                that.newsList = [];
                            }
                            var temNewsList = msg.result && msg.result.newList ? [...msg.result.newList] : [];
                            for (var i = 0; i < temNewsList.length; i++) {
                                that.newsList.push(temNewsList[i]);
                            }
                        } 
                    })
                })
            },
            moreData: function () {
                let that = this;
                that.newsCurrentPage++;
                that.getNewsList();
            },
            getBannerList: function () {
                let that = this;
                let params = {
                    newsType: 1
                };
                that.$nextTick(() => {
                    queryNewsPage(params).then(res => {
                        let msg = res.data != undefined && res.data != null ? res.data : null;
                        if (msg != null && msg.status == 0) {
                          
                            that.bannerList = msg.result && msg.result.newList ? [...msg.result.newList] : [];
                            that.autoPlay=that.bannerList.length>1?true:false;
                        }
                    })
                })
            },
            // 新闻列表
            getList() {
                let params = {
                    currentPage: this.newsCurrentPage,
                    pageSize: this.newsPageSize
                };
                queryNewsPage(params).then(res => {
                    if(res.data.status == 0) {
                        let back = res.data.result;
                        this.newsList = back.newList
                        this.newsTotal = back.pages
                        setTimeout(() => {
                            this.spinning = false
                        },200)
                    }else{
                        this.spinning = false
                    }
                })
            },
            nextPage(changePage) {
                this.spinning = true
                this.newsCurrentPage = changePage
                this.getList()
                window.scroll(0,0)
            }

        },
    
    }
</script>
    
<style scoped>

    .newsbanner {
        width: 1200px;
        height: 420px;
        position: relative;
        cursor: pointer;
        border-radius: 8px;
        overflow: hidden;
    }
        .newsbanner img{
            width:100%;
            height:420px;
        }
        .newsbanner h3 {
            color: #fff;
            background: rgba(28,34,46,.5);
            padding-left: 15px;
            width: 100%;
            height: 57px;
            line-height: 57px;
            text-align: left;
            position: absolute;
            bottom: 0;
            font-size: 24px;
        }
        .newsbanner span{
            
        }
        [v-cloak] {
            display: none;
        }

        .ant-carousel{
            border-radius: 8px;
            overflow: hidden;
        }
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 420px;
  line-height: 420px;
  background: #364d79;
  overflow: hidden;
  /* border-radius: 8px; */
}

.ant-carousel >>> .custom-slick-arrow {
    display: none;
}
.ant-carousel >>> .custom-slick-arrow:before {
    display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
    display: none;
}
.ant-carousel .slick-slide img {
    display: block;
    width: 100%;
    height: 420px;
}
.ant-carousel >>> .slick-slide h3 {
    color: #fff;
    background: rgba(28,34,46,0.5);
    /* background:rgba(0,0,0,0.3); */
    /* opacity:0.8;  */
    padding-left: 15px;
    width: 100%;
    height: 57px;
    line-height: 57px;
    text-align: left;
    position:absolute;
    bottom:0;
    font-size:24px
}
.anticon-left{
    font-size: 16px;
    color: #ffffff;
    line-height: 38px;
    width: 35px;
    height: 35px;
    background: rgba(28,34,46,1);
    border-radius: 0px 12px 12px 0px;
    opacity: 0.6;
    text-align: center;
}
.anticon-right{
    font-size: 16px;
    color: #ffffff;
    line-height: 38px;
    width: 35px;
    height: 35px;
    background: rgba(28,34,46,1);
    border-radius: 12px 0px 0px 12px;
    opacity: 0.6;
    text-align: center;
    margin-left: -20px;
}
.News {
    min-height: calc(100vh - 159px);
    max-height: auto;
    padding-bottom: 54px;
} 
.News .banner{
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
    
}
.custom-slick-arrow{
    display: none !important;
}
.News .banner:hover .custom-slick-arrow{
   display: block !important;
}
.News .content{
    width: 1200px;
    margin: 30px auto 0 auto;
    height: auto;
    /* overflow-y: hidden; */
    /* padding-left: 10px; */
    /* padding-bottom: 50px; */
    /* padding-top: 10px; */
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
/* .content>p{
    clear: both;
    text-align: center;
    padding-top: 50px;
    color: #9AA7B9;
    font-size: 16px;
    cursor: pointer;
    width: 120px;
    margin: auto;
} */
.empty_txt{
    clear: both;
    text-align: center;
    padding: 50px 0;
    color: #9AA7B9;
    font-size: 16px;
    cursor: pointer;
    width: 120px;
    margin: auto;
}
.content>li{
  float: left;
  width: 583px;
  background-color: #fff;
  cursor: pointer;
  margin-left: 30px;
  padding: 20px;
  border-radius: 2px;
  margin-top: 30px;
  position: relative;
}
.content>li:hover{
    -moz-box-shadow:0 0 14px rgba(0,0,0,0.1);
	-webkit-box-shadow:0 0 14px rgba(0,0,0,0.1);
	box-shadow:0 0 14px rgba(0,0,0,0.1);
    /* box-shadow:3px 5px 14px 0px rgba(0,0,0,0.2); */
}
.content>li>h2{
  color: #333A48;
  line-height: 35px;
  font-size: 22px;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
  overflow:hidden;
  height: 75px;
}
.content>li>h2>img{
  margin-right: 10px;
  vertical-align: -4px;
  width: 45px;
}
.content>li>img{
  width:170px;
  height: 115px;
  float: left;
}
.content>li>p{
  float: left;
  width: 320px;
  margin-left: 30px;
  line-height: 30px;
  color: #606978;
  font-size: 16px;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:3;
  overflow:hidden;
  margin-top: -3px;
  height: 90px;
  word-wrap: break-word;
}
.content>li>h3{
    margin-top: 10px;
    font-size: 14px;
    color: #9AA7B9;
    float: left;
    font-weight: 400;
    position: absolute;
    bottom: 16px;
    left: 0;
    margin-left: 220px;
}
    .spanNewBannerTitle {
        max-width: 700px;
        display: inline-block;
        float: left;
        text-overflow: ellipsis;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
    }

    .a-fade-in{
	    animation: fade-in 0.4s linear;
    }

    @keyframes fade-in{
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .pages{
        text-align: center;
        margin:10px 0 40px 0;
        height: 32px;
    }
</style>