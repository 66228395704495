<template>
  <div class="newsitem">
    <div class="news_item" @click="toDetail">
      <h2 class="news_title">
        <img
          v-if="newsData.isNew == '0'"
          class="news_icon"
          src="../../assets/imgs/new_icon.png"
          alt
        />
        {{newsData.newsTitle}}
      </h2>
      <div class="news_desc">
        <div class="news_desc_left">
          <img
            class="news_desc_left_img"
            v-if="newsData.newsPic"
            :src="QiNiuPath + newsData.newsPic"
            alt
          />
        </div>
        <div class="news_desc_right">
          <p class="news_txt">{{newsData.newsIntroduce}}</p>
          <p class="news_time">{{newsData.showTime}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  props: {
    newsData: Object,
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
  },
  methods: {
    toDetail() {
      this.$emit("toDetail", this.newsData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin.scss";

.newsitem {
  .news_item {
    @include wh(590px, inherit);
    padding: 20px 20px 20px 20px;
    box-sizing: border-box;
    transition: all 0.4s ease;
    cursor: pointer;
    margin-bottom: 20px;
    background: #fff;
    &:hover {
      border-radius: 12px;
      box-shadow: 3px 4px 14px 0px rgba(0, 0, 0, 0.15);
      transform: translateY(-10px);
    }
    .news_title {
      @include sc(22px, #333a48);
      font-weight: bold;
      margin-bottom: 30px;
      @include txtflow();
      .news_icon {
        @include wh(45px, 24px);
        margin-right: 10px;
        vertical-align: -4px;
      }
    }
    .news_desc {
      display: flex;
      height: 114px;
      .news_desc_left {
        // @include wh(168px, 114px);
        img {
          @include wh(168px, 114px, 6px);
          margin-right: 29px;
        }
      }
      .news_desc_right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        .news_txt {
          @include sc(16px, #606978);
          @include mtxtflow(3);
          word-break: break-all;
          height: 72px;
        }
        .news_time {
          @include sc(14px, #9aa7b9);
        }
      }
    }
  }
}
</style>