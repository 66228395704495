//������ѯ
import request from '@/utils/http.js';

//��ѯ����
export function queryNewsPage(options) {
    return request({
        url: '/newsPro/selectNewsList',
        method: 'post',
        data: options
    })
}

// 基地风采
export function getBaseStar(options) {
    return request({
        url: '/businessPro/portalConfigStyle',
        method: 'post',
        data: options
    })
}